import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import counties_albers_10m from './counties-albers-10m.json';
import { feature, mesh } from 'topojson-client';

function Util() {
	function copy2clipboard(data) {
		navigator.clipboard.writeText(JSON.stringify(data, null, 2));
	}

	const { counties, states, nation } = counties_albers_10m.objects;
	let geo_path_us = {
		counties: {
			feature: feature(counties_albers_10m, counties),
			mesh: mesh(counties_albers_10m, counties, (a, b) => a !== b && (a.id / 1000 | 0) === (b.id / 1000 | 0))
		},
		states: {
			feature: feature(counties_albers_10m, states),
			mesh: mesh(counties_albers_10m, states, (a, b) => a !== b)
		},
		nation: {
			feature: feature(counties_albers_10m, nation),
			mesh: mesh(counties_albers_10m, nation, (a, b) => a !== b)
		},
	}

	return (
		<Row className="mx-0">
			<Button as={Col} variant="primary" onClick={() => copy2clipboard(geo_path_us.counties.feature)}>counties</Button>
			<Button as={Col} variant="secondary" className="mx-2" onClick={() => copy2clipboard(geo_path_us.states.feature)}>states</Button>
			<Button as={Col} variant="success" onClick={() => copy2clipboard(geo_path_us.nation.feature)}>nation</Button>
		</Row>
	);		
}

export default Util;
