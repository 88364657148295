import { useState, useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as d3 from 'd3';
import Util from './Util.js';
import {Marks, StateMarks, domain, CoCMarks} from './Marks'
import { geoPath } from 'd3-geo';
import usa from './usa.json';
import states from './us-states.json';

function App() {
	return (
		<div className="App">
		  <header className="App-header">
			<h1>Homelessness in the U.S. 2021</h1>
			<HomelessMap width={ 975 } height={ 610 } />
		  </header>
		</div>
	);		
}

function Legend({ colors, domain }) {
	let c = colors.map((c, i) => (
		<div className="MapLegendColor" key={'c' + i} style={{ background: c }}><span className="MapLegendText">{ domain[i] }</span></div>
	))

	return (
		<div className="MapLegend">{ c }</div>
	);
}

function HomelessMap({ width, height }) {
	const legend = ['#ffffcc','#ffeda0','#fed976','#feb24c','#fd8d3c','#fc4e2a','#e31a1c','#bd0026','#800026'];
		
	return (
		<div>
			<Legend colors={ legend } domain={ domain }/>
			<svg viewBox={"0 0 " + width + " " + height}
				style={{
					height,
					width,
					marginRight: "0px",
					marginLeft: "0px",
				}}
			>
				{<Marks/>}
				{<CoCMarks/>}
				{<StateMarks data={states} />}
				<MapUSA />}
			</svg>
		</div>
	);
};

function MapUSA() {
	return (
		<g fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round">
			{/* <circle cx={100} cy={100} r={10} /> */}
			<path d={geoPath()(usa.features[0])} />
		</g>
	)
}

export default App;
