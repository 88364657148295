import { geoPath } from 'd3-geo';
import us_geo from './us-geo.json';
import countyTopoData from './counties-albers-10m.json';
import us_mapped_data from './us-mapped-data-coc-correction.json';
import Tippy from '@tippy.js/react';
import coc_topo from './coc-topo.json';
const topojson = require('topojson-client');
const path = geoPath();

export const domain = [0, 50, 100, 200, 300, 400, 500, 600, 700, 100000];
const colorClass = ['light-yellow', 'yellow', 'dark-yellow', 'light-orange', 'orange', 'light-red', 'red', 'dark-red', 'dark'];

function geoData(data) {
    // Find the corresponding state inside the GeoJSON
    for (let j = 0; j < us_geo.features.length; j++)  {
        let countyData = us_mapped_data[us_geo.features[j].id];
        let coc = countyData.COC;

        if (coc !== undefined) {
            // Copy the data value into the JSON
            let perHundredThousand = (countyData.cocHomelessCount/countyData.cocPop) * 100000;
            us_geo.features[j].properties.homelessCount = countyData.cocHomelessCount;
            us_geo.features[j].properties.perHundredThousand = perHundredThousand
            us_geo.features[j].properties.COC = coc;

            let index = domain.findIndex( value => perHundredThousand < value );
            us_geo.features[j].properties.colorClass = colorClass[index - 1];
        }
    }

    return us_geo;
}

const HomelessToolCountyTip = ({data}) => {
    let homelessCount = data.properties.homelessCount;
    let perHundredThousand = data.properties.perHundredThousand;

    if (typeof(homelessCount) !== 'undefined') {
        return (
            <div className="ToolTip">
                <h4>{data.properties.COC}</h4>
                <p>
                Homeless per 100,000: {perHundredThousand.toLocaleString("en-US")}
                <br/>
                Homeless Count: {homelessCount.toLocaleString("en-US")}
                </p>
            </div>
        );
    } else {
        return (
            <div className="ToolTip">
                <b>{data.properties.name} county, {data.properties.state}, id: {data.id}</b>
            </div>
        );
    }
}

export const Marks = () => (
    <g className="marks">
        { geoData().features.map((feature) => (
            <Tippy key={feature.id} trigger={'click'} theme={'light'} placement = {'auto'} arrow = {false} content = {<HomelessToolCountyTip data = {feature}></HomelessToolCountyTip>}>
                <path className={feature.properties.colorClass} d={path(feature)} />
            </Tippy>
        ))}
    </g>
);

export const StateMarks = ({ data }) => (
    <g className="stateMarks">
        { data.features.map((feature) => (
            <path className={feature.properties.colorClass} d={path(feature)} />
        ))}
    </g>
);

export const CoCMarks= () => (
    <g className="cocMarks">
        { coc_topo.features.map((feature) => (
            <path d={path(feature.cocMarks)}/>
        ))}
    </g>
);
